import { DefaultTheme } from "styled-components";

// See styled.d.ts for where we extend the styled components DefaultTheme

const baseTheme = {
  font: {
    fontFamily: "Roboto, sans-serif",
  },
  // Screen sized used for media query break points and widths / max-widths
  screenSize: {
    xs: 400,
    s: 500,
    m: 800,
    l: 1100,
    xl: 1300,
    xxl: 1500,
  },
  borderRadius: {
    circle: "50%",
    switch: "0.75rem",
    image: "20px",
    button: "5px",
    card: "20px",
    burgerMenu: "20px",
  },
};

export const lightTheme: DefaultTheme = {
  LightOrDark: "light",
  colours: {
    foregroundPrimary:
      "#000000" /* Used for stand out text, example include: h tags, buttons, icons, etc. */,
    foregroundSecondary:
      "#444444" /* Used for general body text, example include: p, ul and ol tags, inputs, etc.  */,
    background: "#ffffff",
    whiteLogoBackground: "#ffffff",
    card: "#F8F9FA",
    lightGrey: "#b8b8b8",
    midGrey: "#8a8a8a",
    disabled: "#cfcfcf",
    darkBlue: "#1D3A86",
    midBlue: "#3F71F8",
    orange: "#FF942A",
    danger: "#ff2929",
    shadow: "rgba(30, 30, 30, 0.2)",
  },
  ...baseTheme,
};

export const darkTheme: DefaultTheme = {
  LightOrDark: "dark",
  colours: {
    foregroundPrimary: "#ffffff",
    foregroundSecondary: "#efefef",
    background: "#171717",
    whiteLogoBackground: "#ffffff",
    card: "#000000",
    lightGrey: "#b8b8b8",
    midGrey: "#8a8a8a",
    disabled: "#cfcfcf",
    darkBlue: "#1D3A86",
    midBlue: "#3F71F8",
    orange: "#FF942A",
    danger: "#ff2929",
    shadow: "rgba(100, 100, 100, 0.6)",
  },
  ...baseTheme,
};
