import React from "react";
import styled, { useTheme } from "styled-components";

export default function NotificationDot({
  notificationNumber,
}: {
  notificationNumber: number;
}): JSX.Element {
  const theme = useTheme();
  return (
    <Dot darkTheme={theme.LightOrDark === "dark"}>
      <span>{notificationNumber}</span>
    </Dot>
  );
}

const Dot = styled.div<{ darkTheme?: boolean }>`
  position: absolute;
  top: -15px;
  right: -15px;
  text-decoration: none;
  background: ${(props) => props.theme.colours.orange};
  border-radius: ${(props) => props.theme.borderRadius.circle};
  height: 16px;
  width: auto;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    height: 13px;
    width: auto;
    min-width: 13px;
    top: -12px;
    right: -12px;
  }
  span {
    font-size: 0.75rem;
    color: ${(props) =>
      props.theme.colours[
        props.darkTheme ? "background" : "foregroundPrimary"
      ]}; /* This theme logic is to keep the dot number black on the orange background */
  }
`;
