import React from "react";
import styled from "styled-components";
import SunSvg from "../assets/images/sun.svg";
import MoonSvg from "../assets/images/moon.svg";

export const Switch = ({
  styles,
  isToggled,
  onToggle,
  showThemeIcons,
  ariaLabel,
}: {
  styles?: React.CSSProperties;
  isToggled: boolean;
  onToggle: () => void;
  showThemeIcons?: boolean;
  ariaLabel?: string;
}): JSX.Element => {
  return (
    <LabelStyles style={styles}>
      <InputStyles
        type="checkbox"
        checked={isToggled}
        onChange={onToggle}
        aria-label={
          showThemeIcons
            ? `Switch input to toggle between light theme and dark theme. ${
                isToggled ? "Dark" : "Light"
              } theme selected`
            : ariaLabel
        }
        tabIndex={0}
      />
      <SpanStyles />
      {showThemeIcons && (
        <>
          <SunImgStyles
            src={SunSvg}
            alt="Small icon of the sun to illustrate light theme mode in the theme switch"
          />
          <MoonImgStyles
            src={MoonSvg}
            alt="Small icon of the moon to illustrate the dark theme mode in the theme switch"
          />
        </>
      )}
    </LabelStyles>
  );
};
// switch
const LabelStyles = styled.label`
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`;
// checkbox
const InputStyles = styled.input`
  // opacity: 0;
  // width: 0;
  // height: 0;
  width: 85%; /* Keep checkbox hidden behind the switch span and images, while allowing outline to be visible on tab focus */
  height: 80%;
  &:checked + span:before {
    transform: translateX(1.25rem);
  }
`;
// slider
const SpanStyles = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.colours.foregroundPrimary};
  border-radius: ${(props) => props.theme.borderRadius.switch};
  &:before {
    z-index: 10;
    position: absolute;
    content: "";
    left: 0.4rem;
    bottom: 0.25rem;
    height: 1rem;
    width: 1rem;
    background: ${(props) => props.theme.colours.background};
    border-radius: ${(props) => props.theme.borderRadius.circle};
    transition: 0.5s;
  }
`;
const SunImgStyles = styled.img`
  position: absolute;
  left: 0.4rem;
  bottom: 0.25rem;
  height: 1rem;
  width: 1rem;
`;
const MoonImgStyles = styled.img`
  position: absolute;
  right: 0.4rem;
  bottom: 0.25rem;
  height: 1rem;
  width: 1rem;
`;
export default Switch;
