import React from "react";
import styled from "styled-components";
import { TwitterSquareIcon } from "./icons/TwitterSquareIcon";
import { FacebookSquareIcon } from "./icons/FacebookSquareIcon";
import { LinkedinSquareIcon } from "./icons/LinkedinSquareIcon";
import { OutboundLinkStyles } from "./LinkStyles";
import { MaxWidthContainer } from "./MaxWidthContainer";

const Footer = (): JSX.Element => {
  return (
    <FooterContainer id="footer" tabIndex={-1}>
      <MaxWidthContainer
        wideLayout
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <OutboundLinkStyles
          href="https://twitter.com/bluebeckstudios"
          aria-label="Blue Beck Twitter page link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterSquareIcon style={IconStyles} />
        </OutboundLinkStyles>
        <OutboundLinkStyles
          href="https://www.facebook.com/BlueBeckLtd"
          aria-label="Blue Beck Facebook page link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookSquareIcon style={IconStyles} />
        </OutboundLinkStyles>
        <OutboundLinkStyles
          href="https://www.linkedin.com/company/blue-beck-ltd./"
          aria-label="Blue Beck LinkedIn page link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinSquareIcon style={IconStyles} />
        </OutboundLinkStyles>
      </MaxWidthContainer>
    </FooterContainer>
  );
};

const IconStyles = {
  width: "2rem",
  height: "2rem",
  maxWidth: "40px",
  maxHeight: "40px",
  margin: "0 5px",
};
const FooterContainer = styled.div`
  overflow-y: hidden; /* This keeps the ScreenReader hidden off the bottom of the page until it has tab focus */
  position: relative; /* This keeps the ScreenReader hidden off the bottom of the page until it has tab focus */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    height: 4rem;
  }
`;

export default Footer;
