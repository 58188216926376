import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";

export const ScrollProgressBar = ({
  styles,
  showBelow,
}: {
  styles?: React.CSSProperties;
  showBelow?: number;
}): JSX.Element => {
  // // Disabled due to blocking clicks in content
  // return <></>;

  const [visible, setVisible] = useState(!showBelow ? true : false);
  const [scrolledPercentage, setScrolledPercentage] = useState(0);

  const onScroll = useCallback(() => {
    showBelow && window.pageYOffset > showBelow
      ? setVisible(true)
      : setVisible(false);
    const windowScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolledPercentage = (windowScroll / height) * 100;
    setScrolledPercentage(scrolledPercentage);
  }, []);

  useEffect(() => {
    if (showBelow) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  });

  const onClickScrollToTop = () => {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {visible && (
        <Container style={styles}>
          <ContentColumn>
            <Button
              onClick={onClickScrollToTop}
              tabIndex={0}
              ariaLabel="Scroll to top button"
            >
              <ProgressBarText>Scroll to top</ProgressBarText>
            </Button>
            <ProgressBarOuter>
              <ProgressBarInner scrolledPercentage={scrolledPercentage} />
            </ProgressBarOuter>
          </ContentColumn>
        </Container>
      )}
    </>
  );
};
const Container = styled.div`
  position: fixed;
  left: 2rem;
  right: 0;
  top: calc(50% - 100px);
  z-index: 1;
  height: 180px;
  width: 0;
  @media (max-width: ${(props) => props.theme.screenSize.m + 70}px) {
    top: 1rem;
    left: 0;
    width: 250px;
    margin: 0 auto;
    height: fit-content;
    // display: none; /* Hidden due to display issues */
  }
`;
const ContentColumn = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  button {
    position: absolute;
    top: 0;
    left: -3rem;
    transform: rotate(-90deg);
    width: 100px;
    text-align: center;
    @media (max-width: ${(props) => props.theme.screenSize.m + 70}px) {
      position: static;
      transform: none;
    }
  }
  @media (max-width: ${(props) => props.theme.screenSize.m + 70}px) {
    width: 250px;
    height: 55px;
    min-height: 55px; /* this stops div collapsing on physical iPhone device */
    align-items: center;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    background: ${(props) => props.theme.colours.background};
    border-radius: ${(props) => props.theme.borderRadius.button};
  }
`;
const ProgressBarText = styled.h5`
  margin: 0;
  transition: all 0.2s ease-in;
  &:hover,
  &:focus-visible {
    color: ${(props) => props.theme.colours.orange};
  }
  @media (max-width: ${(props) => props.theme.screenSize.m + 70}px) {
    background: ${(props) => props.theme.colours.background};
  }
`;
const ProgressBarOuter = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colours.foregroundPrimary};
  width: 2px;
  height: 100px;
  @media (max-width: ${(props) => props.theme.screenSize.m + 70}px) {
    position: static;
    width: 200px;
    height: 2px;
  }
`;
const ProgressBarInner = styled.div<{ scrolledPercentage: number }>`
  background: ${(props) => props.theme.colours.orange};
  width: 2px;
  height: ${({ scrolledPercentage }) => scrolledPercentage}%;
  @media (max-width: ${(props) => props.theme.screenSize.m + 70}px) {
    width: ${({ scrolledPercentage }) => scrolledPercentage}%;
    height: 2px;
  }
`;
