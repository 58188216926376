import React from "react";
import styled from "styled-components";
import { ChevronUpCircle } from "@styled-icons/boxicons-solid/ChevronUpCircle";
import { DefaultColours } from "../../styles/styled";

export const ChevronUpCircleIcon = ({
  style,
  colour,
  hoverColour,
  width,
  height,
  unit,
}: {
  style?: React.CSSProperties;
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
}): JSX.Element => (
  <ChevronUpCircleStyled
    style={style}
    colour={colour}
    hoverColour={hoverColour}
    width={width}
    height={height}
    unit={unit}
  />
);

const ChevronUpCircleStyled = styled(ChevronUpCircle)<{
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
}>`
  color: ${(props) => props.theme.colours[props.colour || "foregroundPrimary"]};
  width: ${(props) => `${props.width || 3}${props.unit || "rem"}`};
  height: ${(props) => `${props.height || 3}${props.unit || "rem"}`};
  transition: all 0.2s ease-in;
  &:hover,
  &:focus-visible {
    cursor: pointer;
    color: ${(props) => props.theme.colours[props.hoverColour || "midBlue"]};
  }
`;
