import styled from "styled-components";

export const Main = styled.main`
  flex: 1; /* makes footer sticky, in conjunctions with flex styles on body in Layout */
  margin: 12rem 0 8rem; /* margin-top 12rem = 6rem header + 6rem spacing */
`;
export const MainIndexPage = styled.main`
  overflow: hidden; /* hide horizontal scroll bar when SVGs animate off screen on landing page scroll */
  flex: 1; /* makes footer sticky, in conjunctions with flex styles on body in Layout */
  margin: 6rem 0 calc(10rem - 40px) 0; /* 6rem header to allow for header. margin-bottom calc(8rem - 40px) accounts for ContactForm LoaderContainer 40px */
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    margin: 6rem 0 calc(8rem - 40px) 0;
  }
  @media (max-width: ${(props) => props.theme.screenSize.xs}px) {
    margin: 6rem 0 calc(6rem - 40px) 0;
  }
`;
