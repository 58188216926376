import React, { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import BBLogo from "../assets/images/bb-logo.png";
import { useWindowDimensions } from "../utils/useWindowDimensions";
import Switch from "./Switch";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { LinkInternal } from "./LinkInternal";
import { NavLinks, NavLinksAccordion } from "./NavLinks";
import { Burger } from "./Burger";

const Header = ({
  toggleTheme,
  onClickScroll,
  hideCareersNotificationDot,
}: {
  toggleTheme: () => void;
  onClickScroll: () => void;
  hideCareersNotificationDot?: boolean;
}): JSX.Element => {
  const theme = useTheme();

  const [isNavOpen, setNavOpen] = useState(false);

  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    // close burger nav if user resizes window
    setNavOpen(false);
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  const onScroll = () => {
    setNavOpen(false);
  };

  const toggleNav = useCallback(() => {
    setNavOpen((prev) => !prev);
  }, []);

  return (
    <NavStyled isNavOpen={isNavOpen}>
      <MaxWidthContainer wideLayout style={{ flexDirection: "column" }}>
        <NavRowContent>
          <LinkInternal to="/" ariaLabel="Clickable link to the Home page">
            <LogoStyles alt="Blue Beck logo" src={BBLogo} />
          </LinkInternal>
          <InteractiveElementsContainer>
            <NavLinks
              onClickScroll={onClickScroll}
              hideCareersNotificationDot={hideCareersNotificationDot}
            />
            <Switch
              showThemeIcons
              isToggled={theme.LightOrDark === "dark"}
              onToggle={toggleTheme}
            />
          </InteractiveElementsContainer>
          <Burger isOpen={isNavOpen} toggleOpen={toggleNav} />
        </NavRowContent>
        <NavLinksAccordion
          style={{ marginBottom: isNavOpen ? "2rem" : 0 }}
          isOpen={isNavOpen}
          toggleTheme={toggleTheme}
          onClickScroll={onClickScroll}
        />
      </MaxWidthContainer>
    </NavStyled>
  );
};

const NavStyled = styled.nav<{ isNavOpen: boolean }>`
  position: absolute;
  z-index: 30; /* The svg with the highest z-index on the landing page is 25, so this overlays them */
  left: 0;
  right: 0;
  min-height: 6rem;
  transition: box-shadow 0.2s ease-in;
  box-shadow: ${(props) =>
    props.isNavOpen
      ? // `0 2px 5px -5px ${props.theme.colours.foregroundPrimary}`
        `0 0 10px ${props.theme.colours.shadow}`
      : "none"};
`;
const NavRowContent = styled.div`
  margin: 1rem auto 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    padding: 0;
  }
`;
const LogoStyles = styled.img`
  height: 5rem;
  &:hover {
    cursor: pointer;
  }
`;
const InteractiveElementsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: none;
  }
`;
export default Header;
