import React from "react";

const Spacer = ({
  style,
  height,
  unit,
}: {
  style?: React.CSSProperties;
  height?: number;
  unit?: "px" | "rem";
}): JSX.Element => (
  <div
    style={{
      ...style,
      height: `${height || 1}${unit || "rem"}`,
    }}
  />
);

export default Spacer;
