import React from "react";
import styled from "styled-components";

export const MaxWidthContainer = ({
  style,
  children,
  midLayout,
  wideLayout,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  midLayout?: boolean;
  wideLayout?: boolean;
}): JSX.Element => (
  <>
    {wideLayout ? (
      <WideMaxWidthContainerStyled style={style}>
        {children}
      </WideMaxWidthContainerStyled>
    ) : midLayout ? (
      <MidMaxWidthContainerStyled style={style}>
        {children}
      </MidMaxWidthContainerStyled>
    ) : (
      <MaxWidthContainerStyled style={style}>
        {children}
      </MaxWidthContainerStyled>
    )}
  </>
);

// Set max width here to reduce number of characters per row as nicer to read
const MaxWidthContainerStyled = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.screenSize.m}px;
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    padding: 0 2rem;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    padding: 0 1rem;
  }
`;
const MidMaxWidthContainerStyled = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.screenSize.l}px;
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    padding: 0 2rem;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    padding: 0 1rem;
  }
`;
const WideMaxWidthContainerStyled = styled.div`
  width: calc(100% - 10rem);
  max-width: ${(props) => props.theme.screenSize.xxl}px;
  margin: 0 auto;
  display: flex;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    width: calc(100% - 4rem);
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    width: calc(100% - 2rem);
  }
`;
