import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
// import { getCookieConsentValue, Cookies } from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import styled from "styled-components";
import { LinkInternal } from "./LinkInternal";
import { Button } from "./Button";
import { CloseCircleIcon } from "./icons/CloseCircleIcon";

const CookieConsent = (): JSX.Element => {
  const [isBannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setBannerVisible(true);
    }, 500);
  }, []); /* Stop flash of banner while cookie / local storage completes. Hacky but works. Opacity then fades the banner in */

  const isBrowser = () => typeof window !== "undefined";

  const getValue = (key: string, defaultValue: boolean) => {
    return isBrowser() && window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key) as string)
      : defaultValue;
  };

  const setValue = (key: string, value: string) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const useStickyState = (defaultValue: boolean, key: string) => {
    const [value, setter] = useState(() => {
      return getValue(key, defaultValue);
    });

    useEffect(() => {
      setValue(key, value);
    }, [key, value]);

    return [value, setter];
  };

  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [cookieConsentGiven, setCookieConsentGiven] = useStickyState(
    false,
    "cookieConsentGiven"
  );

  //   const hasGoogleAnalyticsCookie = getCookieConsentValue(
  //     "gatsby-gdpr-google-analytics"
  //   );
  //   const onDecline = () => {
  //     Cookies.remove("gatsby-gdpr-google-analytics");
  //   };

  const [dismissed, setDismissed] = useState(false);

  const onDismiss = () => {
    setDismissed(true);
  };

  const EnableAnalytics = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true";
    setCookieConsentGiven(true);
  };

  return (
    <>
      {!cookieConsentGiven && !dismissed && (
        <CookieBanner isBannerVisible={isBannerVisible}>
          <p>
            This site uses cookies.
            <br /> See our&nbsp;
            <LinkInternal to="/privacy" tabIndex={0}>
              privacy policy&nbsp;
            </LinkInternal>
            for details
          </p>
          <Button
            onClick={onDismiss}
            style={{
              border: "0",
              boxShadow: "none",
              padding: "5px",
              position: "absolute",
              top: "10px",
              right: "10px",
              margin: "0",
            }}
          >
            <CloseCircleIcon />
          </Button>
          <AcceptButton onClick={EnableAnalytics}>Ok great!</AcceptButton>
        </CookieBanner>
      )}
    </>
  );
};

const CookieBanner = styled.div<{ isBannerVisible: boolean }>`
  transition: all 1s ease;
  opacity: ${(props) => (props.isBannerVisible ? 1 : 0)};
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 1rem 4rem;
  text-align: center;
  color: ${(props) => props.theme.colours.foregroundSecondary};
  background: ${(props) => props.theme.colours.background};
  border-top-left-radius: ${(props) => props.theme.borderRadius.image};
  border-top: 1px solid ${(props) => props.theme.colours.lightGrey};
  border-left: 1px solid ${(props) => props.theme.colours.lightGrey};
`;
const AcceptButton = styled.button`
  display: flex;
  margin: auto;
  align-items: center;
  height: 2.5rem;
  max-height: 40px;
  padding: 0.5rem 1.5rem;
  line-height: 100%;
  font-size: 1rem;
  color: ${(props) => props.theme.colours.background};
  background: ${(props) => props.theme.colours.midBlue};
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.button};
  transition: all 0.2s ease-in;
  &:active {
    outline: none;
  }
  &:active,
  &:hover,
  &:focus-visible {
    cursor: pointer;
    background: ${(props) => props.theme.colours.darkBlue};
  }
`;

export default CookieConsent;
