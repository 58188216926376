import React from "react";
import styled from "styled-components";
import { DefaultColours } from "../styles/styled";

export const Button = ({
  style,
  children,
  type,
  disabled,
  onClick,
  initialColor,
  largeFont,
  ariaLabel,
  tabIndex,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
  initialColor?: keyof DefaultColours;
  largeFont?: boolean;
  ariaLabel?: string;
  tabIndex?: number;
}): JSX.Element => {
  const color = initialColor ? initialColor : "foregroundPrimary";
  const fontSize = largeFont ? "1.5rem" : "1rem";

  return (
    <ButtonStyled
      style={{
        ...style,
        color,
        fontSize,
      }}
      type={type || "button"}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    >
      {children}
    </ButtonStyled>
  );
};
export const ButtonStyled = styled.button`
  border: none;
  font-weight: bold;
  color: ${(props) => props.theme.colours.foregroundPrimary};
  background: ${(props) => props.theme.colours.background};
  margin: 0.5rem 0;
  padding: 0;
  transition: all 0.2s ease-in;
  transition-property: color;
  &:hover,
  &:focus-visible {
    cursor: pointer;
    color: ${(props) => props.theme.colours.midBlue};
  }
  &:disabled {
    color: ${(props) => props.theme.colours.disabled};
  }
`;
