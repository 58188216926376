import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

export const NavLink = ({
  children,
  activeStyle,
  to,
  tabIndex,
}: {
  children?: React.ReactNode;
  activeStyle: React.CSSProperties;
  to: string;
  tabIndex?: number;
}): JSX.Element => {
  return (
    <NavLinkStyled
      // activeStyle={activeStyle}
      to={to}
      tabIndex={tabIndex || 0}
      getProps={({ isPartiallyCurrent }) =>
        isPartiallyCurrent
          ? {
              style: {
                ...activeStyle,
              },
            }
          : {}
      }
    >
      {children}
    </NavLinkStyled>
  );
};
const NavLinkStyled = styled(Link)`
  position: relative;
  color: ${(props) => props.theme.colours.foregroundPrimary};
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 100%;
  margin-right: 2rem;
  transition: all 0.2s ease-in;
  &:active {
    outline: none;
  }
  &:hover,
  &:focus-visible {
    cursor: pointer;
    color: ${(props) => props.theme.colours.midBlue};
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    margin: 0 0 10px 1rem;
  }
`;

export default NavLink;
