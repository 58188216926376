import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

export const useWindowDimensions = (): {
  windowWidth: number | null;
  windowHeight: number | null;
} => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const windowWidth = hasWindow ? window.innerWidth : null;
    const windowHeight = hasWindow ? window.innerHeight : null;
    return {
      windowWidth,
      windowHeight,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  useEffect(() => {
    if (hasWindow) {
      handleResize();
      // limit handleResize callback function to x milliseconds to stop excess work for the browser on window resize
      window.addEventListener("resize", throttle(handleResize, 200));
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
