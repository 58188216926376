import React from "react";
import { DefaultColours } from "../../styles/styled";
import { Button } from "../Button";
import { ChevronUpCircleIcon } from "./ChevronUpCircleIcon";

export const ChevronUpCircleButton = ({
  style,
  onClick,
}: {
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
  onClick: () => void;
}): JSX.Element => (
  <Button
    style={{ ...style, margin: "0", background: "transparent" }}
    onClick={onClick}
    tabIndex={0}
    ariaLabel="Scroll to top button"
  >
    <ChevronUpCircleIcon />
  </Button>
);
