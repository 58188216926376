import React, { MutableRefObject, useCallback, useEffect, useRef } from "react";
import { navigate } from "gatsby";
/* import createHistory from "history/createBrowserHistory"; */
import { createBrowserHistory } from "history";
import NProgress from "nprogress";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "../styles/theme";
import { useDarkMode } from "../utils/useDarkMode";
import Footer from "./Footer";
import Header from "./Header";
import { SilentAny } from "../utils/types";
import ScreenReaderLink from "./ScreenReaderLink";
import ScrollToTopCircle from "./ScrollToTopCircle";
import { ScrollProgressBar } from "./ScrollProgressBar";
import CookieConsent from "./CookieConsent";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 16px; /* fallback */
    font-size: min(max(12px, 4vw), 16px);
    overflow-y: scroll;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    font-family: ${(props) => props.theme.font.fontFamily};
  }
  * {
  // remove default a11y border and add more specifie one
  // it will now show for tabbing but not mouse clicks
  outline: none;
  &:focus-visible {
    outline: 2px solid ${(props) =>
      props.theme.LightOrDark === "dark"
        ? props.theme.colours.orange
        : props.theme.colours.midBlue};
  }
  }
  html, body, #___gatsby, 
  // #gatsby-focus-wrapper 
  {
    height: 100%;
  }
  #___gatsby, #gatsby-focus-wrapper  {
    min-height: 100vh;
  }
  body {
    margin: 0;
    line-height: 1.5;
    background: ${(props) => props.theme.colours.background};
  }
  h1, h2, h3, h4, h5, legend, label {
    font-weight: bolder;
    color: ${(props) => props.theme.colours.foregroundPrimary};
    margin: 0;
  }
  p, ul, ol, input, textarea {
    color: ${(props) => props.theme.colours.foregroundSecondary};
  }
  h1, h2, h3 {
    line-height: 1.2;
  }
  h1 {
    font-size: 3.5rem;
    @media (max-width: ${(props) => props.theme.screenSize.m}px) {
      font-size: 2.75rem;
    }
  }
  h2 {
    font-size: 3rem;
    @media (max-width: ${(props) => props.theme.screenSize.m}px) {
      font-size: 2.5rem;
    }
  }
  h3 {
    font-size: 2rem;
    @media (max-width: ${(props) => props.theme.screenSize.m}px) {
      font-size: 1.5rem;
    }
  }
  h4 {
    font-size: 1.25rem;
    @media (max-width: ${(props) => props.theme.screenSize.m}px) {
      font-size: 1.1rem;
    }
  }
  h5 {
    font-size: 1rem
  }
  }
  legend {
    font-size: 3rem;
    @media (max-width: ${(props) => props.theme.screenSize.m}px) {
      font-size: 2.5rem;
    }
  }
  input, textarea {
    background: ${(props) => props.theme.colours.background};
    padding: 0.5rem 0;
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.screenSize.s}px) {
      font-size: 0.8rem;
    }
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colours.midBlue};
    transition: all 0.2s ease-in;
    &:active {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colours.orange};
    }
    // &:visited {
    //   color: ${(props) => props.theme.colours.darkBlue};
    // }
  }
  ul, ol {
    list-style-type: none;
    padding-left: 14px;
    li {
      margin-bottom: 0.5rem;
      &:before {
        content: '-'; margin-left: -14px; margin-right: 10px;
      }
  }
}
`;

const Layout = ({
  children,
  location,
  showScrollProgressBar,
  contactFormRef,
}: {
  location?: SilentAny /* used Silent any to fix "object is of type 'unknown'" tsc warnings */;
  // location?: LocationState;
  children?: React.ReactNode;
  showScrollProgressBar?: boolean;
  contactFormRef?: MutableRefObject<HTMLDivElement>;
}): JSX.Element => {
  useEffect(() => {
    NProgress.configure({ minimum: 0.1 });
    NProgress.start();
    NProgress.done();
  }, []);

  const { theme, toggleTheme } = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const bottomOfPageRef = useRef() as MutableRefObject<HTMLDivElement>;
  const footerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const isIndexPage = location && location.pathname === "/" ? true : false;
  const isCareersPage =
    location && location.pathname === "/careers" ? true : false;

  useEffect(() => {
    const history = createBrowserHistory();
    if (location && location.state?.referral) {
      if (contactFormRef) {
        contactFormRef.current.scrollIntoView({ behavior: "smooth" });
        // for a11y tab focus, we need to add ref.current.focus here
        // preventScroll maintains the smooth scrll whilst moving the tab focus
        contactFormRef.current.focus({ preventScroll: true });
      } else {
        bottomOfPageRef.current.scrollIntoView({ behavior: "smooth" });
      }
      // This resets referral location.state.referral property,
      // so reloadig the index page after navigating from a different page
      // doesn't scroll the user to bottom of page
      const state = { ...(history.location.state as Record<string, unknown>) };
      delete state.referral;
      history.replace({ ...history.location, state } as SilentAny);
    }
  }, []);

  const onClickScroll = useCallback(() => {
    if (contactFormRef) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
      // for a11y tab focus, we need to add ref.current.focus here
      // preventScroll maintains the smooth scrll whilst moving the tab focus
      contactFormRef.current.focus({ preventScroll: true });
    } else {
      bottomOfPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const goHomeFromReferral = useCallback(() => {
    // used older navigate method because passing state via Gatsby Link component was inconsistent
    navigate("/", { state: { referral: true } });
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      {/* <AppProvider> */}
      <GlobalStyle />
      <div
        id="top"
        // flex styles on body make footer sticky in conjunction with "flex: 1" on main in MainStyles
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <ScreenReaderLink href="#main" text="Skip to content" />
        <ScreenReaderLink href="#footer" text="Skip to footer" />
        <CookieConsent />
        <Header
          toggleTheme={toggleTheme}
          onClickScroll={isIndexPage ? onClickScroll : goHomeFromReferral}
          hideCareersNotificationDot={isCareersPage}
        />
        {children}
        <div ref={footerRef} tabIndex={-1}>
          <Footer />
        </div>
        {showScrollProgressBar ? (
          <ScrollProgressBar showBelow={60} />
        ) : (
          <ScrollToTopCircle showBelow={100} />
        )}
        <div ref={bottomOfPageRef} tabIndex={-1} />
      </div>
      {/* </AppProvider> */}
    </ThemeProvider>
  );
};

export default Layout;
