import React from "react";
import styled from "styled-components";
import { Linkedin } from "@styled-icons/fa-brands/Linkedin";
import { DefaultColours } from "../../styles/styled";

export const LinkedinSquareIcon = ({
  style,
  colour,
  hoverColour,
  width,
  height,
  unit,
}: {
  style?: React.CSSProperties;
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
}): JSX.Element => (
  <LinkedInSquareStyled
    style={style}
    colour={colour}
    hoverColour={hoverColour}
    width={width}
    height={height}
    unit={unit}
  />
);

const LinkedInSquareStyled = styled(Linkedin)<{
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
}>`
  color: ${(props) => props.theme.colours[props.colour || "midBlue"]};
  width: ${(props) => `${props.width || 2}${props.unit || "rem"}`};
  height: ${(props) => `${props.height || 2}${props.unit || "rem"}`};
  maxwidth: 40px;
  maxheight: 40px;
  transition: all 0.2s ease-in;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colours[props.hoverColour || "midBlue"]};
  }
`;
