import React from "react";
import styled from "styled-components";
import { CloseCircle } from "@styled-icons/ionicons-solid/CloseCircle";
import { DefaultColours } from "../../styles/styled";

export const CloseCircleIcon = ({
  style,
  colour,
  hoverColour,
  width,
  height,
  unit,
}: {
  style?: React.CSSProperties;
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
}): JSX.Element => (
  <CloseCircleStyled
    style={style}
    colour={colour}
    hoverColour={hoverColour}
    width={width}
    height={height}
    unit={unit}
  />
);

const CloseCircleStyled = styled(CloseCircle)<{
  colour?: keyof DefaultColours;
  hoverColour?: keyof DefaultColours;
  width?: number;
  height?: number;
  unit?: "px" | "rem";
}>`
  color: ${(props) => props.theme.colours[props.colour || "foregroundPrimary"]};
  width: ${(props) => `${props.width || 1.5}${props.unit || "rem"}`};
  height: ${(props) => `${props.height || 1.5}${props.unit || "rem"}`};
  min-width: 20px;
  min-height: 20px;
  transition: all 0.2s ease-in;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colours[props.hoverColour || "midBlue"]};
  }
`;
