import React, { useEffect, useRef, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import styled, { useTheme } from "styled-components";
import { animated, useSpring } from "react-spring";
import NavLink from "./NavLink";
import { ContactNavLink } from "./LinkStyles";
import NotificationDot from "./NotificationDot";
import Switch from "./Switch";

export const NavLinks = ({
  style,
  hideCareersNotificationDot,
  onClickScroll,
}: {
  style?: React.CSSProperties;
  hideCareersNotificationDot?: boolean;
  onClickScroll: () => void;
}): JSX.Element => {
  const theme = useTheme();

  const activeLinkStyles = {
    color: theme.colours.midBlue,
  };
  return (
    <NavLinksContainer style={style}>
      <NavLink to="/services" activeStyle={activeLinkStyles}>
        Services
      </NavLink>
      <NavLink to="/projects" activeStyle={activeLinkStyles}>
        Projects
      </NavLink>
      <NavLink to="/blog" activeStyle={activeLinkStyles}>
        Blog
      </NavLink>
      <StaticQuery
        query={query}
        render={(data) => {
          if (!data) {
            return null;
          }
          const totalJobs = data?.allStrapiJobs.totalCount;
          return (
            <NavLink activeStyle={activeLinkStyles} to="/careers">
              Careers
              {totalJobs > 0 && !hideCareersNotificationDot && (
                <NotificationDot notificationNumber={totalJobs} />
              )}
            </NavLink>
          );
        }}
      />
      <ContactNavLink onClick={onClickScroll} role="link">
        Contact
      </ContactNavLink>
    </NavLinksContainer>
  );
};
const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
`;
const query = graphql`
  query {
    # filter out the dummy blank job, used to prevent Gatsby crash when no other Jobs set in Strapi
    # allStrapiJobs(filter: { jobTitle: { ne: "BlankJob" } }) {
    allStrapiJobs {
      totalCount
    }
  }
`;

export const NavLinksAccordion = ({
  style,
  isOpen,
  toggleTheme,
  onClickScroll,
}: {
  style?: React.CSSProperties;
  isOpen: boolean;
  toggleTheme: () => void;
  onClickScroll: () => void;
}): JSX.Element => {
  const theme = useTheme();

  const [accordionTotalHeight, setAccordianTotalHeight] = useState(0);
  const accordionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (accordionRef.current !== null) {
      // ref.current.scrollHeight allows you to measure the height of "overflow: hidden" elements, but ref.current.clientHeight does not.
      setAccordianTotalHeight(accordionRef.current.scrollHeight);
    }
  });

  const springProps = useSpring({
    // from: { height: defaultHeight },
    // to: { height: contentHeight },
    // height: isOpen ? `${accordionTotalHeight}px` : `${descriptionHeight}px`,
    height: isOpen ? `${accordionTotalHeight}px` : "0px",
    // the following config is equivalent to react-spring config.default
    config: { mass: 1, tension: 170, friction: 26 },
  });

  return (
    <NavLinksAccordionContainer>
      <animated.div
        ref={accordionRef}
        style={{
          ...style,
          height: springProps.height,
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <NavLinks
          style={{ flexDirection: "column" }}
          onClickScroll={onClickScroll}
        />
        <Switch
          showThemeIcons
          isToggled={theme.LightOrDark === "dark"}
          onToggle={toggleTheme}
        />
      </animated.div>
    </NavLinksAccordionContainer>
  );
};
const NavLinksAccordionContainer = styled.div`
  display: none;
  width: 100%;
  background: ${(props) => props.theme.colours.background};
  z-index: 10;
  a {
    margin: 1rem 0 0 0;
    &:first-of-type {
      margin: 0;
    }
  }
  button {
    margin: 1rem 0;
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: flex;
    align-items: center;
  }
`;
