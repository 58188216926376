import { useEffect, useState } from "react";

export const useDarkMode = (): {
  theme: string;
  toggleTheme: () => void;
} => {
  const [theme, setTheme] = useState("dark");

  const setMode = (mode: string) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    theme === "light" ? setMode("dark") : setMode("light");
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    localTheme && setTheme(localTheme);
  }, []);
  return {
    theme,
    toggleTheme,
  };
};
