import React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import { SilentAny } from "../utils/types";

export const SEO = ({
  description,
  keywords,
  title,
  image,
  url,
}: //   author,
{
  description?: string;
  keywords?: string[];
  title?: string;
  image?: SilentAny;
  url?: string;
  //   author?: string;
}): JSX.Element => {
  const siteUrl = process.env.URL || "https://www.bluebeck.co.uk";

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { siteMetadata } = data.site;
        const metaDescription = description || siteMetadata.description;
        const metaTitle = `Blue Beck - ${title}` || siteMetadata.title;
        const metaUrl = url || siteMetadata.siteUrl;
        // const metaAuthor = author || data.site.siteMetadata.author;
        /// const metaImage = `${siteUrl}${image ? image : siteMetadata.image}`;
        const metaImage = `${siteUrl}${
          image
            ? image
            : data.bbOpenGraph.childImageSharp.fluid.src || siteMetadata.image
        }`;
        const metaKeywords = keywords || [
          "Blue Beck",
          "Macclesfield",
          "App",
          "Website",
          "Mobile",
          "React Native",
          "React",
          "Gatsby",
        ];
        return (
          <Helmet
            title={title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`,
              },
              //   {
              //     name: `twitter:creator`,
              //     content: metaAuthor,
              //   },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : []
            )}
          />
        );
      }}
    />
  );
};

const detailsQuery = graphql`
  query defaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
        siteUrl
      }
    }
    bbOpenGraph: file(relativePath: { eq: "bb-open-graph.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

// function SEO({
//   description,
//   lang,
//   meta,
//   // image: metaImage,
//   title,
// }: //   pathname,
// {
//   description: string;
//   lang: string;
//   meta: SilentAny;
//   title: string;
//   //   pathname: string;
// }): JSX.Element {
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//             description
//             # siteUrl
//             keywords
//           }
//         }
//       }
//     `
//   );

//   const metaDescription = description || site.siteMetadata.description;
//   //   const image =
//   //     metaImage && metaImage.src
//   //       ? `${site.siteMetadata.siteUrl}${metaImage.src}`
//   //       : null;
//   const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

//   return (
//     <Helmet
//       htmlAttributes={{
//         lang,
//       }}
//       title={title}
//       titleTemplate={`%s | ${site.siteMetadata.title}`}
//       link={
//         canonical
//           ? [
//               {
//                 rel: "canonical",
//                 href: canonical,
//               },
//             ]
//           : []
//       }
//       meta={[
//         {
//           name: `description`,
//           content: metaDescription,
//         },
//         {
//           name: "keywords",
//           content: site.siteMetadata.keywords.join(","),
//         },
//         // {
//         //   property: `og:title`,
//         //   content: title,
//         // },
//         // {
//         //   property: `og:description`,
//         //   content: metaDescription,
//         // },
//         // {
//         //   property: `og:type`,
//         //   content: `website`,
//         // },
//         // {
//         //   name: `twitter:creator`,
//         //   content: site.siteMetadata.author,
//         // },
//         // {
//         //   name: `twitter:title`,
//         //   content: title,
//         // },
//         // {
//         //   name: `twitter:description`,
//         //   content: metaDescription,
//         // },
//       ]
//         // .concat(
//         //   metaImage
//         //     ? [
//         //         {
//         //           property: "og:image",
//         //           content: image,
//         //         },
//         //         {
//         //           property: "og:image:width",
//         //           content: metaImage.width,
//         //         },
//         //         {
//         //           property: "og:image:height",
//         //           content: metaImage.height,
//         //         },
//         //         {
//         //           name: "twitter:card",
//         //           content: "summary_large_image",
//         //         },
//         //       ]
//         //     : [
//         //         {
//         //           name: "twitter:card",
//         //           content: "summary",
//         //         },
//         //       ]
//         // )
//         .concat(meta)}
//     />
//   );
// }

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// };

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
//   //   image: PropTypes.shape({
//   //     src: PropTypes.string.isRequired,
//   //     height: PropTypes.number.isRequired,
//   //     width: PropTypes.number.isRequired,
//   //   }),
//   pathname: PropTypes.string,
// };

// export default SEO;
