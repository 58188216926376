import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";

export const LinkInternal = ({
  style,
  children,
  bold,
  largeFont,
  to,
  target,
  rel,
  ariaLabel,
  tabIndex,
}: {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  bold?: boolean;
  largeFont?: boolean;
  to: string;
  target?: string;
  rel?: string;
  ariaLabel?: string;
  tabIndex?: number;
}): JSX.Element => {
  const fontWeight = bold ? "bold" : "normal";
  const fontSize = largeFont ? "1.5rem" : "1rem";

  return (
    <LinkStyled
      style={{
        ...style,
        fontWeight,
        fontSize,
      }}
      to={to}
      target={target}
      rel={rel}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
    >
      {children}
    </LinkStyled>
  );
};
const LinkStyled = styled(Link)`
  width: fit-content;
  text-decoration: none;
  color: ${(props) => props.theme.colours.midBlue};
  transition: all 0.2s ease-in;
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colours.orange};
  }
  svg {
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colours.orange};
    }
  }
  // &:visited {
  //   color: ${(props) => props.theme.colours.darkBlue};
  // }
`;
