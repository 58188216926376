import React from "react";
import styled from "styled-components";

const ScreenReaderLink = ({
  href,
  text,
}: {
  href: "#main" | "#footer";
  text: string;
}): JSX.Element => {
  /* 
  Note: Using anchor tags here rather than Link from Gatsby because of bug where Link keeps focus when tabbing,
  but we want tab focus to shit to main or footer, which anchor allows 
  */
  return <ScreenReaderLinkStyled href={href}>{text}</ScreenReaderLinkStyled>;
};

const ScreenReaderLinkStyled = styled.a`
  position: absolute;
  z-index: 40; /* this is above the header nav at z-index 30 */
  width: 250px;
  top: 0;
  left: calc(50% - 125px);
  font-size: 1.25rem;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.theme.colours.midBlue};
  background-color: ${(props) => props.theme.colours.background};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius.card};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius.card};
  border: solid 2px ${(props) => props.theme.colours.midBlue};
  border-top: none;
  text-decoration: none;
  padding: 20px;
  transform: translateY(-100%);
  transition: transform 0.3s;
  &:focus-visible {
    transform: translateY(0%);
    outline: none;
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    font-size: 1.1rem;
  }
`;

export default ScreenReaderLink;
