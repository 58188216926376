import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

export const Burger = ({
  style,
  isOpen,
  toggleOpen,
}: {
  style?: React.CSSProperties;
  isOpen: boolean;
  toggleOpen: () => void;
}): JSX.Element => {
  // the following config is equivalent to react-spring config.default
  const springConfig = { mass: 1, tension: 170, friction: 26 };

  const topDiv = useSpring({
    transform: isOpen ? "rotate(45deg)" : "rotate(0deg)",
    transformOrigin: isOpen ? "0px" : "1px",
    config: springConfig,
  });
  const middleDiv = useSpring({
    transform: isOpen ? "translateX(-2rem)" : "translateX(0rem)",
    opacity: isOpen ? "0" : "1",
  });
  const bottomDiv = useSpring({
    transform: isOpen ? "rotate(-45deg)" : "rotate(0deg)",
    transformOrigin: isOpen ? "0px" : "1px",
    config: springConfig,
  });

  return (
    <BurgerButton style={style} onClick={toggleOpen}>
      <animated.div style={topDiv} />
      <animated.div style={middleDiv} />
      <animated.div style={bottomDiv} />
    </BurgerButton>
  );
};
const BurgerButton = styled.button`
  display: none;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  padding: 0;
  flex-direction: column;
  justify-content: space-around;
  div {
    width: 2rem;
    height: 2px;
    background: ${(props) => props.theme.colours.foregroundPrimary};
    border-radius: ${(props) => props.theme.borderRadius.burgerMenu};
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    display: flex;
  }
`;
