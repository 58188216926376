// import { OutboundLink } from "gatsby-plugin-google-gtag"; /* npm install this and add back in later if bothered about tracking outbound links specifically */
import styled from "styled-components";

/*****************************************************************************************************
 *** Note: Both Link and NavLink have been refactored into separate component with their own props ***
 *****************************************************************************************************/

// export const OutboundLinkStyles = styled(OutboundLink)`
export const OutboundLinkStyles = styled.a`
  color: ${(props) => props.theme.colours.midBlue};
  text-decoration: none;
  transition: all 0.2s ease-in;
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colours.orange};
  }
  // &:visited {
  //   color: ${(props) => props.theme.colours.darkBlue};
  // }
`;

export const ContactNavLink = styled.button`
  color: ${(props) => props.theme.colours.foregroundPrimary};
  background: none;
  border: none;
  padding: 0;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 100%;
  margin-right: 2rem;
  transition: all 0.2s ease-in;
  &:active {
    outline: none;
  }
  &:active,
  &:hover,
  &:focus-visible {
    cursor: pointer;
    color: ${(props) => props.theme.colours.midBlue};
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    margin: 0 0 10px 1rem;
  }
`;

export const TitleButton = styled.button`
  height: 3rem;
  max-height: 48px;
  color: ${(props) => props.theme.colours.background};
  background: ${(props) => props.theme.colours.midBlue};
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.button};
  padding: 0.5rem 2rem;
  font-size: 1rem;
  line-height: 100%;
  transition: all 0.2s ease-in;
  &:active {
    outline: none;
  }
  &:active,
  &:hover,
  &:focus-visible {
    cursor: pointer;
    background: ${(props) => props.theme.colours.darkBlue};
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    margin: 0 0 10px 1rem;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    padding: 0.5rem 1rem;
  }
`;
