import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChevronUpCircleButton } from "./icons/ChevronUpCircleButton";
// import useWindowDimensions from "../utils/useWindowDimensions";

const ScrollToTopCircle = ({
  styles,
  showBelow,
}: {
  styles?: React.CSSProperties;
  showBelow?: number;
}): JSX.Element => {
  //   const { windowWidth } = useWindowDimensions();

  const [visible, setVisible] = useState(!showBelow ? true : false);

  useEffect(() => {
    if (showBelow) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  });

  const onScroll = () => {
    showBelow && window.pageYOffset > showBelow
      ? setVisible(true)
      : setVisible(false);
  };

  const onClick = () => {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  };

  return (
    <BackToTopCircleContainer
      style={styles}
      // windowWidth={windowWidth}
    >
      {visible && <ChevronUpCircleButton onClick={onClick} />}
    </BackToTopCircleContainer>
  );
};

const BackToTopCircleContainer = styled.div`
  z-index: 10;
  position: fixed;
  right: 4.8rem; /* 5rem - 0.2rem which accounts for space between svg and path child */
  bottom: 2rem;
  @media (max-width: ${(props) => props.theme.screenSize.l}px) {
    right: 1.8rem; /* 2rem - 0.2rem which accounts for space between svg and path child */
    bottom: 2rem;
  }
  @media (max-width: ${(props) => props.theme.screenSize.s}px) {
    right: 0.8rem; /* 1rem - 0.2rem which accounts for space between svg and path child */
    bottom: 1rem;
  }
  }
`;

export default ScrollToTopCircle;
